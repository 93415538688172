/* ./src/index.css */


*,
*::before,
*::after {
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply mx-auto max-w-full px-4 md:px-5 xl:max-w-[1280px];
  }

  .subtitle {
    @apply text-base text-lightGray;
  }
  .subtitle.dark {
    @apply text-gray;
  }
}
.timeline_slider .single_timeline {
  position: relative;
}
.timeline_slider .single_timeline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  @apply bg-purple;
  z-index: 5;
}
.timeline_slider .single_timeline::after {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 20px);
  height: 2px;
  @apply bg-lightwhite/20;
}
.swiper-pagination-fraction {
  @apply sr-only opacity-0 pointer-events-none;
}
